import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";

import { Button } from "../../../common/button";
import locationIcon from "../../../../media/icons/location.svg";
import { ReactComponent as FavoriteIcon } from "../../../../media/icons/unsave.svg";
import calendarIcon from "../../../../media/icons/calendar.svg";
import qIcon from "../../../../media/icons/q.svg";
import styles from "./questionBox.module.css";

export const QuestionBox = ({
  questionId,
  added,
  answerTimes,
  button,
  classNames,
  city,
  home,
  state,
  textClass,
  title,
  saved,
  saveQuestion,
  summary,
  tab,
  unSaveQuestion
}) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.box, classNames, home ? styles.home : styles.question)}>
      <img className={home ? styles.questionHome : styles.questionQA} src={qIcon} alt="" />
      <div className={cn(styles.title, textClass)}>
        <Link to={`/question-answer/${questionId}?tab=${tab}`} className={styles.titleLink}>
          {title}
        </Link>
      </div>
      <div>{summary}</div>
      <hr className={styles.hr} />
      <div className={styles.panel}>
        <div className={styles.container}>
          <div className={styles.answerFlex}>
            <div className={styles.answers}>
              {answerTimes && (
                <Link to={`/question-answer/${questionId}?tab=${tab}`} className={styles.titleLink}>
                  ({answerTimes}) {answerTimes === 1 ? <>{t("dashboard_qa_all_answer")}</> : <>{t("dashboard_qa_all_answers")}</>}
                </Link>
              )}
            </div>
            <div className={cn(styles.city, styles.cityDesktop)}>
              <img className={styles.infoIcon} src={locationIcon} alt="" />
              {city ? `${city}, ` : ""} {state}
            </div>
            {saved ? (
              <Button
                className={cn(styles.iconButtonFilled, styles.iconButtonMobile, "button--clear")}
                onClick={() => unSaveQuestion(questionId)}
              >
                <div className={styles.iconFilled} />
                <FavoriteIcon />
              </Button>
            ) : (
              <Button className={cn(styles.iconButton, styles.iconButtonMobile, "button--clear")} onClick={() => saveQuestion(questionId)}>
                <div className={styles.iconFill} />
                <FavoriteIcon />
              </Button>
            )}
          </div>
          <div className={styles.dateFlex}>
            <div className={cn(styles.city, styles.cityMobile)}>
              <img className={styles.infoIcon} src={locationIcon} alt="" />
              {city ? `${city}, ` : ""} {state}
            </div>
            {saved ? (
              <Button
                className={cn(styles.iconButtonFilled, styles.iconButtonDesktop, "button--clear")}
                onClick={() => unSaveQuestion(questionId)}
              >
                <div className={styles.iconFilled} />
                <FavoriteIcon />
              </Button>
            ) : (
              <Button className={cn(styles.iconButton, styles.iconButtonDesktop, "button--clear")} onClick={() => saveQuestion(questionId)}>
                <div className={styles.iconFill} />
                <FavoriteIcon />
              </Button>
            )}
            <div className={styles.date}>
              <img className={styles.infoIcon} src={calendarIcon} alt="" />
              {added && format(new Date(added.split(" ").join("T")), "MMM do yyyy, h:mm a")}
            </div>
          </div>
        </div>
        {button && <Link to="/legal">{t("dashboard_common_readmore")}</Link>}
      </div>
    </div>
  );
};
