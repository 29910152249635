import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import Dropdown from "../dropdown";
import styles from "./socials.module.css";
import RemoveButton from "../removeButton";
import { socialsToArray, socialsToObject } from "../../../helpers";
import Input from "../input";
import instagram from "../../../media/icons/grayInstagram.svg";
import facebook from "../../../media/icons/grayFacebook.svg";
import twitter from "../../../media/icons/grayTwitter.svg";
import linkedin from "../../../media/icons/grayLinkedin.svg";
import youtube from "../../../media/icons/grayYoutube.svg";
import yelp from "../../../media/icons/grayYelp.svg";

import instagramActive from "../../../media/icons/instagramActive.svg";
import facebookActive from "../../../media/icons/facebookActive.svg";
import twitterActive from "../../../media/icons/twitterActive.svg";
import linkedinActive from "../../../media/icons/linkedinActive.svg";
import youtubeActive from "../../../media/icons/youtubeActive.svg";
import yelpActive from "../../../media/icons/yelpActive.svg";

const blankSocials = [
  { social_name: "linkedin", social_url: "" },
  { social_name: "linkedin company page", social_url: "" },
  { social_name: "facebook", social_url: "" },
  { social_name: "twitter", social_url: "" },
  { social_name: "youtube", social_url: "" },
  { social_name: "instagram", social_url: "" },
  { social_name: "Yelp Profile URL", social_url: "" }
];

const icons = {
  instagram,
  facebook,
  twitter,
  linkedin,
  "linkedin company page": linkedin,
  youtube,
  "Yelp Profile URL": yelp
};

const iconsActive = {
  instagram: instagramActive,
  facebook: facebookActive,
  twitter: twitterActive,
  linkedin: linkedinActive,
  youtube: youtubeActive,
  "linkedin company page": linkedinActive,
  "Yelp Profile URL": yelpActive
};

const translationKeys = {
  instagram: "dashboard_listings_edit_social_instagram",
  facebook: "dashboard_listings_edit_social_facebook",
  twitter: "dashboard_listings_edit_social_twitter",
  linkedin: "dashboard_listings_edit_social_linkedinprofile",
  "linkedin company page": "dashboard_listings_edit_social_linkedincompany",
  youtube: "dashboard_listings_edit_social_youtube",
  "Yelp Profile URL": "inputyelp"
};

const Socials = ({
  selectedSocials,
  submitChanges,
  handleRemove,
  validation,
  showRequiredText,
  validationYelp,
  validationLinkedinCompany,
  validationLinkedInProfile
}) => {
  if (
    Object.keys(validation).length &&
    !validation.enabled &&
    Object.keys(validationYelp).length &&
    !validationYelp.enabled &&
    Object.keys(validationLinkedinCompany).length &&
    !validationLinkedinCompany.enabled &&
    Object.keys(validationLinkedInProfile).length &&
    !validationLinkedInProfile.enabled
  ) {
    return null;
  }
  const [selectedList, setSelectedList] = useState([]);

  useEffect(() => {
    if (!selectedList.length) {
      setSelectedList(selectedSocials);
    } else {
      const newList = selectedSocials.map((item, index) => {
        return selectedSocials[index] ? selectedSocials[index] : item;
      });
      setSelectedList(newList);
    }
  }, [selectedSocials.length]);

  const selectedSocialsList = { ...socialsToObject(blankSocials, true), ...socialsToObject(selectedSocials) };
  const selectedSocialsListArray = Object.keys(selectedSocialsList).filter(item => {
    if (
      (!validationYelp.enabled && item === "Yelp Profile URL") ||
      (!validationLinkedinCompany.enabled && item === "linkedin company page") ||
      (!validationLinkedInProfile.enabled && item === "linkedin")
    ) {
      return false;
    }

    return item;
  });

  const isRequired = Object.keys(validation).length ? validation.required : true;
  const isRequiredText = validation.required && !selectedSocials?.length && showRequiredText;
  const { t } = useTranslation();

  const normalizeSocialListToArray = selectedSocialsListArray.map(item => {
    return selectedSocialsList[item];
  });

  const handleSubmit = values => {
    const normalizedValues = { ...socialsToArray(values)[0] };

    const data = normalizeSocialListToArray
      .map(item => {
        if (item.social_name === normalizedValues.social_name) {
          return normalizedValues;
        }
        return item;
      })
      .filter(item => Boolean(item.social_url));

    submitChanges({ socials: data }, "socials", "save_social");
  };

  const title = `${t("dashboard_single_listing_social")}${selectedSocials.length ? ` (${selectedSocials.length})` : ""}`;

  return (
    <Dropdown title={title} isRequired={isRequired} isRequiredText={isRequiredText}>
      <div className={styles.content}>
        <div className={styles.languageContent}>
          {selectedSocialsListArray.map(socialName => {
            return (
              <SocialItem
                // eslint-disable-next-line react/no-array-index-key
                key={`${socialName}-social-key`}
                handleSubmit={handleSubmit}
                handleRemove={handleRemove}
                selected={selectedSocialsList[socialName]}
                name={socialName}
              />
            );
          })}
        </div>
      </div>
    </Dropdown>
  );
};

const SocialItem = ({ selected, handleSubmit: onSubmit, name }) => {
  const { t } = useTranslation();
  const { social_url: socialUrl } = selected;
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const containerRef = useRef();

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const formik = useFormik({
    initialValues: { ...selected, isEdited: false },
    onSubmit: values => {
      const { social_name, social_url } = values;
      onSubmit({ [name]: { social_name, social_url } });
    }
  });

  const { values, handleSubmit, setFieldValue } = formik;

  const handleChange = e => {
    setFieldValue("social_url", e.target.value);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }
  };

  const handleRemove = () => {
    setFieldValue("social_url", "");

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }
  };

  const renderPlaceholder = socialName => {
    if (socialName === "linkedin company page") {
      return "LinkedIn Company URL";
    }

    if (socialName === "linkedin") {
      return "LinkedIn Profile URL";
    }

    return t(translationKeys[socialName]);
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);

      if (values.isEdited) {
        handleSubmit();
        setFieldValue("isEdited", false);
      }
    }
  };

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (isTouched) {
          setIsTouched(false);

          if (values.isEdited) {
            handleSubmit();
            setFieldValue("isEdited", false);
          }
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [values.isEdited, values.social_url]);

  return (
    <div
      ref={containerRef}
      onTouchStart={handleTouchStart}
      className={styles.socialItem}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      <div className={styles.flex}>
        <img className={styles.icon} src={socialUrl ? iconsActive[values.social_name] : icons[values.social_name]} alt="" />

        <Input
          type="text"
          value={values.social_url || ""}
          id={values.social_name}
          name="social_name"
          placeholder={renderPlaceholder(values.social_name)}
          onChange={handleChange}
          classNameWrap={styles.inputWrap}
          className={styles.input}
        />
        <RemoveButton className={styles.removeButton} isHide={selected.isNew} onClick={handleRemove} isCrossIcon />
      </div>
    </div>
  );
};

export default Socials;
