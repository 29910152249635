import React, { useState, useRef } from "react";
import cn from "classnames";
import format from "date-fns/format";
import { differenceInCalendarDays } from "date-fns";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import { ReactComponent as DropdownIcon } from "../../../../media/icons/dropdown-arrow.svg";
import styles from "./newListing.module.css";
import { Avatar } from "../avatar";
import { modals as modalsConstant, statuses, filterTypes } from "../../../../constants/listings";
import { DowngradePeriod } from "../downgradePeriod";
import { ControlMenu } from "../controlMenu";
import Filter from "../filter";

const NewListing = ({
  listingsList,
  openModal,
  loading,
  listActiveDirectories,
  setListActiveDirectories,
  filters,
  listActiveListingType,
  setListActiveListingType,
  listActiveSponsorOptions,
  setListActiveSponsorOptions,
  children,
  listActiveChange,
  setListActiveChange,
  listActiveStatus,
  setListActiveStatus,
  listingsListLength,
  appliedCount,
  isDraft,
  team,
  setIsDraft,
  isJobs,
  isEvents,
  isOrganizations
}) => {
  const [activeFilter, setActiveFilter] = useState({ isActive: false, type: "" });
  const { t } = useTranslation();
  const disableAll = !listingsListLength && !appliedCount ? styles.disableAll : "";
  const refDirectories = useRef();
  const refStatus = useRef();
  const refChanges = useRef();
  const refOption = useRef();

  const getPositions = () => {
    return {
      directories: refDirectories.current?.offsetLeft,
      status: refStatus.current?.offsetLeft,
      changes: refChanges.current?.offsetLeft,
      option: refOption.current?.offsetLeft
    };
  };

  const renderListings = () => {
    return listingsList?.map((item, index) => {
      return <Listing team={team} openModal={openModal} listing={item} key={item.listing_id} index={index} />;
    });
  };

  const handleActiveFilter = (isActive, type) => {
    setActiveFilter({ isActive, type: type || "" });
  };

  const handleToggleFilter = (isActive, type) => {
    if (activeFilter.isActive) {
      setActiveFilter({ isActive: false, type: "" });
    } else {
      setActiveFilter({ isActive: true, type: type || "" });
    }
  };

  const renderName = () => {
    if (isEvents) {
      return (
        <span className={cn(styles.headItem, styles.headItemNoActive)}>
          <span>{t("dashboard_listings_events_table_name")}</span>
        </span>
      );
    }

    if (isOrganizations) {
      return <span className={cn(styles.headItem, styles.headItemNoActive)}>{t("dashboard_listings_organizations_table_name")}</span>;
    }

    if (isJobs) {
      return <span className={cn(styles.headItem, styles.headItemNoActive)}>{t("dashboard_listings_jobs_table_name")}</span>;
    }

    return <span className={cn(styles.headItem, styles.headItemNoActive)}>{t("dashboard_listings_all_table_name")}</span>;
    /*
    return (
      <button
        ref={refDirectories}
        type="button"
        className={cn(styles.headItem, activeFilter.type && activeFilter.type === filterTypes.directory ? styles.headItemActive : "")}
        onClick={() => handleToggleFilter(true, filterTypes.directory)}
      >
        <span>
          {t("dashboard_listings_table_directory")}
          <DropdownIcon />
        </span>
      </button>
    );
    */
  };

  return (
    <div className={styles.mainListings}>
      {activeFilter.isActive && (
        <Filter
          setActiveFilter={handleActiveFilter}
          filters={filters}
          setListActiveDirectories={setListActiveDirectories}
          listActiveDirectories={listActiveDirectories}
          setListActiveListingType={setListActiveListingType}
          listActiveListingType={listActiveListingType}
          setListActiveSponsorOptions={setListActiveSponsorOptions}
          listActiveSponsorOptions={listActiveSponsorOptions}
          listActiveChange={listActiveChange}
          setListActiveChange={setListActiveChange}
          listActiveStatus={listActiveStatus}
          setListActiveStatus={setListActiveStatus}
          loading={loading}
          appliedCount={appliedCount}
          isDesktop
          filterType={activeFilter.type}
          getPositions={getPositions}
          listingsListLength={listingsList?.length}
          isDraft={isDraft}
          setIsDraft={setIsDraft}
        />
      )}
      <div className={cn(styles.head, disableAll)}>
        {renderName()}
        <button
          ref={refStatus}
          type="button"
          className={cn(styles.headItem, activeFilter.type === filterTypes.status ? styles.headItemActive : "")}
          onClick={() => handleToggleFilter(true, filterTypes.status)}
        >
          <span>
            {t("dashboard_listings_all_table_status")}
            <DropdownIcon />
          </span>
        </button>
        <button
          ref={refChanges}
          type="button"
          className={cn(styles.headItem, activeFilter.type === filterTypes.changes ? styles.headItemActive : "")}
          onClick={() => handleToggleFilter(true, filterTypes.changes)}
        >
          <span>
            {t("dashboard_listings_all_table_changes")}
            <DropdownIcon />
          </span>
        </button>
        <button
          ref={refOption}
          type="button"
          className={cn(styles.headItem, activeFilter.type === filterTypes.option ? styles.headItemActive : "")}
          onClick={() => handleToggleFilter(true, filterTypes.option)}
        >
          <span>
            {t("dashboard_listings_all_table_type")}
            <DropdownIcon />
          </span>
        </button>
      </div>

      <div>
        {renderListings()}
        <div className={styles.pagination}>{children}</div>
      </div>
    </div>
  );
};

const Listing = ({ listing, index, openModal, team }) => {
  const {
    attorney,
    product_name: productName,
    listings_image_name: listingsImageName,
    listing_id: listingId,
    listing_url: listingUrl,
    is_premium: isPremium,
    _canceled_to_date: canceledDate,
    expire_date: expireDate,
    updated: updatedDate,
    is_draft: isDraft,
    change_status: changeStatus,
    publish_status: publishStatus,
    _profession: listingType,
    _city: city,
    _state: state,
    paused
  } = listing;
  const isActiveStatus = changeStatus === statuses.active;
  const isPendingPayment = changeStatus === statuses.pendingPayment;
  const isDowngradePeriod = (canceledDate ? differenceInCalendarDays(new Date(canceledDate), new Date()) : 0) >= 1;
  const isExpired = changeStatus === statuses.expired;
  const { t } = useTranslation();
  const [activeDropDown, setActiveDropdown] = useState(false);
  const listingsTypes = ["/my-events", "/my-jobs", "/my-organizations"];
  const isEvensOrJobsPage = listingsTypes.some(item => item.includes(listingType.toLowerCase()));
  const listingPathname = isEvensOrJobsPage ? `/my-${listingType.toLowerCase()}` : "/listings";
  const history = useHistory();
  const isAllListings = history.location.pathname === "/listings";

  const handleToggleDropDown = () => {
    setActiveDropdown(prev => !prev);
  };

  const renderStatus = () => {
    if (publishStatus === statuses.active) {
      return (
        <div className={styles.status}>
          <div>
            <a className={styles.statusActive} href={listingUrl} target="_blank" rel="noopener noreferrer">
              {t("dashboard_single_listing_approved")}
            </a>
            {expireDate && <span className={styles.date}>Expire on {format(new Date(expireDate), "MM/dd/yyyy")}</span>}
          </div>
        </div>
      );
    }

    if (publishStatus === statuses.pendingPayment) {
      return (
        <div className={styles.status}>
          <div>
            <a className={styles.statusActive} href={listingUrl} target="_blank" rel="noopener noreferrer">
              {t("pendingpayment")}
            </a>
            {expireDate && <span className={styles.date}>Expire on {format(new Date(expireDate), "MM/dd/yyyy")}</span>}
          </div>
        </div>
      );
    }

    if (publishStatus === statuses.paused) {
      return (
        <div className={styles.status}>
          <div>
            <span className={styles.statusUnActive}>{t("dashboard_listings_status_paused")}</span>
            {expireDate && <span className={styles.date}>Expire on {format(new Date(expireDate), "MM/dd/yyyy")}</span>}
          </div>
        </div>
      );
    }

    if (publishStatus === statuses.pendingPayment) {
      return (
        <div className={styles.status}>
          <div>
            <span className={styles.statusUnActive}>{t("pendingpayment")}</span>
            {expireDate && <span className={styles.date}>Expire on {format(new Date(expireDate), "MM/dd/yyyy")}</span>}
          </div>
        </div>
      );
    }

    if (publishStatus === statuses.expired) {
      return (
        <div className={styles.status}>
          <div>
            <span className={cn(styles.statusUnActive, styles.statusUnActiveExpired)}>{t("dashboard_common_expired")}</span>
            {expireDate && <span className={styles.date}>Expire on {format(new Date(expireDate), "MM/dd/yyyy")}</span>}
          </div>
        </div>
      );
    }
    if (publishStatus === statuses.neverPublished) {
      return (
        <div className={styles.status}>
          <div>
            <span className={styles.statusUnActive}>{t("dashboard_listings_status_neverpublished")}</span>
            {expireDate && <span className={styles.date}>Expire on {format(new Date(expireDate), "MM/dd/yyyy")}</span>}
          </div>
        </div>
      );
    }

    if (publishStatus === statuses.deleted) {
      return (
        <div className={styles.status}>
          <div>
            <span className={styles.statusUnActive}>{t("dashboard_listings_status_deleted")}</span>
          </div>
        </div>
      );
    }

    if (publishStatus === statuses.transferred) {
      return (
        <div className={styles.status}>
          <div>
            <span className={styles.statusUnActive}>{t("dashboard_listings_status_transferred")}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  const renderChanges = () => {
    if (changeStatus === statuses.approved) {
      return (
        <div className={styles.changes}>
          {isDraft ? (
            <div>
              <p className={cn(styles.changesText, styles.changesTextGrey)}>{t("dashboard_listings_status_unpublishedchanges")}</p>
              {updatedDate && <span className={styles.date}>since {format(new Date(updatedDate), "MM/dd/yyyy")}</span>}
            </div>
          ) : (
            <div>
              <p className={cn(styles.changesText, styles.changesTextGreen)}>{t("dashboard_listings_status_approved")}</p>
              {updatedDate && <span className={styles.date}>on {format(new Date(updatedDate), "MM/dd/yyyy")}</span>}
            </div>
          )}
        </div>
      );
    }

    if (changeStatus === statuses.pending) {
      return (
        <div className={styles.changes}>
          {isDraft ? (
            <div>
              <p className={cn(styles.changesText, styles.changesTextGrey)}>{t("dashboard_listings_status_unpublishedchanges")}</p>
              {updatedDate && <span className={styles.date}>since {format(new Date(updatedDate), "MM/dd/yyyy")}</span>}
            </div>
          ) : (
            <div>
              <p className={cn(styles.changesText, styles.changesTextYellow)}>{t("dashboard_listings_status_pendingreview")}</p>
            </div>
          )}
        </div>
      );
    }

    if (changeStatus === statuses.rejected) {
      return (
        <div className={styles.changes}>
          <div>
            {publishStatus && <p className={cn(styles.changesText, styles.changesTextRed)}>{t("dashboard_listings_status_rejected")}</p>}
            {isDraft && !publishStatus ? (
              <div>
                <p className={cn(styles.changesText, styles.changesTextGrey)}>{t("dashboard_listings_status_unpublishedchanges")}</p>
                {updatedDate && <span className={styles.date}>since {format(new Date(updatedDate), "MM/dd/yyyy")}</span>}
              </div>
            ) : null}
          </div>
        </div>
      );
    }

    return null;
  };

  const renderOptions = () => {
    const typeOption = isPremium ? <span className={styles.sponsor}>Sponsor</span> : <span className={styles.free}>Free</span>;

    return (
      <div>
        {typeOption}
        {!isPremium && !isExpired && (
          <button
            type="button"
            className={styles.options}
            onClick={() => openModal(isPendingPayment ? modalsConstant.payPending : modalsConstant.upgrade, index)}
          >
            {t("upgrade")}
          </button>
        )}

        {isExpired && (
          <>
            {canceledDate ? (
              <button
                type="button"
                className={styles.options}
                onClick={() => openModal(isPremium ? modalsConstant.renew : modalsConstant.upgrade, index)}
              >
                {t("dashboard_listings_table_renew")}
              </button>
            ) : (
              <button type="button" className={styles.options} onClick={() => openModal(modalsConstant.downgrade, index)}>
                Downgrade
              </button>
            )}
          </>
        )}
        {isDowngradePeriod && (
          <DowngradePeriod time={canceledDate} handleDowngrade={() => openModal(modalsConstant.downgradePeriod, index)} />
        )}
      </div>
    );
  };

  return (
    <div className={cn(styles.listingsItem, isPremium ? styles.listingsItemActive : "")}>
      <button
        type="button"
        className={cn(styles.mobileDropDown, activeDropDown ? styles.mobileDropDownActive : "")}
        onClick={handleToggleDropDown}
      >
        <DropdownIcon />
      </button>
      <div className={cn(styles.listingsContent, !activeDropDown ? styles.listingsContentUnActive : "", styles.listingsContentFirst)}>
        <Avatar
          attorneyName={attorney}
          listingId={listingId}
          membership={productName}
          imageName={listingsImageName}
          isActiveStatus={isActiveStatus}
          listingPathname={listingPathname}
        />
        <div className={styles.listingDetail}>
          <Link to={`${listingPathname}/${listingId}${team?.value ? `?user_id=${team.value}` : ""}`} className={styles.membership}>
            {attorney}
          </Link>

          <p className={styles.attorney}>
            {!isAllListings ? (
              <>
                {state || city ? (
                  <>
                    {city}
                    {state ? `${city ? ", " : ""}${state}` : <>{productName}</>}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>{productName}</>
            )}
          </p>
        </div>
      </div>

      <div className={cn(styles.content, activeDropDown ? styles.contentActive : "")}>
        <div className={cn(styles.listingsContent, styles.listingsContentSecond)}>
          <span className={styles.listingsLabel}>Status</span>
          {renderStatus()}
        </div>
        <div className={cn(styles.listingsContent, styles.listingsContentThird)}>
          <span className={styles.listingsLabel}>Changes</span>

          {renderChanges()}
        </div>
        <div className={cn(styles.listingsContent, styles.listingsContentFourth)}>
          <span className={styles.listingsLabel}>Option</span>
          {renderOptions()}
        </div>
        <ControlMenu
          isDots
          promptClassName={styles.promptControlMenu}
          className={styles.controlMenu}
          canceledDate={canceledDate}
          paused={paused}
          status={changeStatus}
          listing_id={listingId}
          isSponsor={isPremium}
          openModal={openModal}
          index={index}
          team={team}
          handleUpgrade={() => openModal(isPendingPayment ? modalsConstant.payPending : modalsConstant.upgrade, index)}
          publishStatus={publishStatus}
          listingType={listingType}
        />
      </div>
    </div>
  );
};

export default NewListing;
