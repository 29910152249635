import React, { useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";

import { ReactComponent as PencilIcon } from "../../../media/icons/edit-pencil-small.svg";
import { RedStar } from "../redStar";
import Label from "../label";
import RenderPhoto from "../renderPhoto";
import { URL as URLRegex } from "../../../constants/regex";
import Input from "../../common/input";
import PhotoModal from "../cropImage";
import styles from "./listingInfo.module.css";
import { useValidationError } from "../../../helpers/hooks";

const ListingInfo = ({
  defaultImage,
  showRequiredText,
  validationPhoto,
  validationName,
  validationBusiness,
  validationWebsite,
  attorney,
  title,
  website,
  submitChanges,
  isActiveModalPhoto,
  deleteImage,
  isJobListing,
  id,
  handleSubmitImage,
  preloaders
}) => {
  const { t } = useTranslation();
  const input = useRef();
  const containerRef = useRef();
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [file, setFile] = useState(null);

  const isRequiredPhoto = validationPhoto.required && !defaultImage?.src;
  const requiredError = isRequiredPhoto && showRequiredText ? styles.requiredError : "";
  const photoContainerRequired = isRequiredPhoto && showRequiredText ? styles.photoContainerRequired : "";
  const contentActive = isMouseInBlock && !isActiveModalPhoto ? styles.contentActive : "";

  const isRequiredBusiness = Object.keys(validationBusiness).length ? validationBusiness.required : true;
  const isRequiredWebsite = Object.keys(validationWebsite).length ? validationWebsite.required : true;
  const isRequiredName = Object.keys(validationName).length ? validationName.required : true;

  const validate = values => {
    const errors = {};
    const { title: titleField, website: websiteField, attorney: attorneyField } = values;
    const requiredText = "This field is mandatory";
    const invalidUrlText = "Please use the full URL";

    if (!attorneyField && isRequiredName) {
      errors.attorney = requiredText;
    }

    if (!websiteField && isRequiredWebsite) {
      errors.website = requiredText;
    }

    if (!titleField && isRequiredBusiness) {
      errors.title = requiredText;
    }

    if (websiteField && !websiteField.match(URLRegex)) {
      errors.website = invalidUrlText;
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: { attorney, title, website, isEdited: false },
    validate,
    onSubmit: values => {
      submitChanges({ attorney: values.attorney, title: values.title, website: values.website }, "test");
    }
  });

  const { errors, touched, values, handleSubmit } = formik;

  const handleSubmitAttorney = () => {
    submitChanges({ attorney: values.attorney }, "attorney", "save_fullname");
  };

  const handleMouseLeaveAttorney = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);

      if (values.attorney && values.isEdited && !errors.attorney) {
        formik.setFieldValue("isEdited", false);
        handleSubmitAttorney();
      }
    }
  };

  const handleSubmitWebsite = () => {
    submitChanges({ website: values.website }, "website", "save_website");
  };

  const handleSubmitTitle = () => {
    submitChanges({ title: values.title }, "title", "save_headline");
  };

  const handleMouseLeaveWebsite = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);

      if (values.website && values.isEdited && !errors.website) {
        formik.setFieldValue("isEdited", false);
        handleSubmitWebsite();
      }
    }
  };

  const handleMouseLeaveTitle = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);

      if (values.title && values.isEdited && !errors.title) {
        formik.setFieldValue("isEdited", false);
        handleSubmitTitle();
      }
    }
  };

  const handleChange = e => {
    formik.setFieldValue(e.target.name, e.target.value);

    if (!values.isEdited) {
      formik.setFieldValue("isEdited", true);
    }
  };

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);

      if (values.isEdited) {
        formik.setFieldValue("isEdited", false);
      }
    }
  };

  useEffect(() => {
    if (
      (!values.attorney && isRequiredName && showRequiredText) ||
      (!values.website && isRequiredWebsite && showRequiredText) ||
      (!values.title && isRequiredBusiness && showRequiredText)
    ) {
      handleSubmit();
    }
  }, [showRequiredText]);

  const renderFullNameLabel = () => {
    if (isJobListing) {
      return "Job Title";
    }

    if (validationName.custom_name) {
      return validationName.custom_name;
    }

    return "Full Name";
  };

  const renderFullNamePlaceholder = () => {
    if (isJobListing) {
      return "Enter your job title";
    }
    return "Enter your full name";
  };

  const renderWebsiteUrlLabel = () => {
    if (isJobListing) {
      return "Job Apply URL";
    }

    if (validationWebsite.custom_name) {
      return validationWebsite.custom_name;
    }

    return t("website");
  };

  const handleFileInput = e => {
    e.persist();
    const { files } = e.target;

    handleUploadImage(files[0]);
  };

  const handleUploadImage = files => {
    if (files) {
      const formData = new FormData();
      formData.append("image_body", files);
      formData.append("listing_id", id);
      formData.append("is_default", "1");
      formData.append("command", "upload");
      handleSubmitImage(formData);
    }
  };

  const handleOpenFile = () => {
    setModalActive(false);

    setTimeout(() => {
      setModalActive(true);
    }, 200);
  };

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target) && !errors.website) {
        if (isTouched) {
          setIsTouched(false);

          if (values.isEdited) {
            handleSubmitWebsite();
            handleMouseLeaveTitle();
            handleSubmitAttorney();

            formik.setFieldValue("isEdited", false);
          }
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [values.isEdited, values.website]);

  const onDrop = useCallback(acceptedFiles => {
    const acceptedFile = acceptedFiles[0];
    if (acceptedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFile);
      reader.onload = () => {
        setFile(reader.result);

        handleOpenFile();
      };
    } else {
      console.error("Invalid file object Listing Info");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/webp": [],
      "image/gif": [],
      "image/png": []
    },
    maxSize: 5000000
  });

  useValidationError(Object.values(errors).some(item => Boolean(item)) ? { listingInfo: "Error" } : "isRemove", "listingInfo");

  return (
    <div
      className={cn(styles.content, contentActive)}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
      ref={containerRef}
    >
      {modalActive && (
        <PhotoModal
          handleSubmitImage={handleSubmitImage}
          closeModal={() => setModalActive(false)}
          clearAllModals={() => setModalActive(false)}
          listingId={id}
          loading={preloaders.photo}
          isOpen={!file}
          preloadFile={file || defaultImage?.src}
          defaultImage={defaultImage}
          handleRemovePreloaderFile={() => setFile(null)}
          deleteImage={deleteImage}
        />
      )}

      <div className={styles.relative}>
        <div className={styles.leftSection} {...getRootProps()}>
          <RenderPhoto className={photoContainerRequired} defaultImage={defaultImage} />
          <span className={styles.sizeInfo}>100 x 100 / 5 MB Max</span>
          <input
            {...getInputProps()}
            ref={input}
            accept=".png, .jpg, .jpeg, .webp, .gif"
            onChange={e => handleFileInput(e)}
            id="upload"
            name="upload"
            className={styles.fileInput}
            type="file"
          />
          {isRequiredPhoto && (
            <p className={cn(styles.required, requiredError)}>
              {validationPhoto.custom_name || "Profile photo required"} <RedStar isActive />
            </p>
          )}
        </div>
        <button type="button" className={styles.edit} onClick={handleOpenFile}>
          <PencilIcon />
        </button>
      </div>

      <div className={styles.rightSection}>
        <div className={styles.inputContent} onMouseLeave={handleMouseLeaveAttorney}>
          <Label htmlFor="fullName">
            {renderFullNameLabel()} {isRequiredName && <RedStar isActive />}
          </Label>
          <Input
            type="text"
            placeholder={renderFullNamePlaceholder()}
            className={styles.input}
            error={errors.attorney}
            id="attorney"
            isInvalid={errors.attorney}
            name="attorney"
            onChange={handleChange}
            required={isRequiredName}
            value={values.attorney}
            classNameError={styles.errorText}
            classNameInvalid={styles.inputInvalid}
          />
        </div>
        {Object.keys(validationBusiness).length && validationBusiness.enabled ? (
          <div className={styles.inputContent} onMouseLeave={handleMouseLeaveTitle}>
            <Label htmlFor="businessName">
              {validationBusiness.custom_name || "Business Name"} {isRequiredBusiness && <RedStar isActive />}
            </Label>
            <Input
              type="text"
              placeholder="Enter your business name"
              className={styles.input}
              error={errors.title}
              id="title"
              isInvalid={errors.title && touched.title}
              name="title"
              onChange={handleChange}
              required={isRequiredBusiness}
              value={values.title}
              classNameError={styles.errorText}
              classNameInvalid={styles.inputInvalid}
            />
          </div>
        ) : null}

        <div onMouseLeave={handleMouseLeaveWebsite}>
          <Label htmlFor="website">
            {renderWebsiteUrlLabel()} {isRequiredWebsite && <RedStar isActive />}
          </Label>
          <Input
            type="text"
            placeholder="ex. https://www.andybernanrd.com"
            className={styles.input}
            error={errors.website}
            id="website"
            isInvalid={errors.website && touched.website}
            name="website"
            onChange={handleChange}
            value={values.website}
            required={isRequiredWebsite}
            classNameError={styles.errorText}
            classNameInvalid={styles.inputInvalid}
          />
        </div>
      </div>
    </div>
  );
};

export default ListingInfo;
