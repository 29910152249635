import React from "react";

import cn from "classnames";
import styles from "./filtration.module.css";
import { ReactComponent as SearchIcon } from "../../../../media/icons/search.svg";
import { ReactComponent as FilterIcon } from "../../../../media/icons/filter.svg";
import { useDebounce } from "../../../../helpers/hooks";

/* todo: remove */
const Filtration = ({ setFilterByNameAndText, onOpenFilterModal }) => {
  const { searchTerm, setSearchTerm } = useDebounce(setFilterByNameAndText);

  const onSearch = () => {
    setFilterByNameAndText(searchTerm);
  };

  return (
    <div className={styles.filtration}>
      <div className={styles.flex}>
        <div className={styles.searchField}>
          <button type="button" className={styles.searchFieldButton} onClick={onSearch}>
            <SearchIcon className={styles.icon} />
          </button>

          <input
            type="search"
            className={styles.inboxSearch}
            placeholder="Search your Inbox"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
        <button type="button" className={cn("button--clear", styles.filter)} onClick={onOpenFilterModal}>
          <FilterIcon />
        </button>
      </div>
    </div>
  );
};

export default Filtration;
