import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useHistory, NavLink } from "react-router-dom";

import SelectInput from "../../common/selectInput";
import styles from "./tabs.module.css";

const MainTabs = ({ className }) => {
  const [filterType, setFilterType] = useState({ value: "", label: "" });

  const myAccount = { value: "/referrals", label: "Referral Requests" };
  const teams = { value: "/paid-leads?page=0&tab=notpurchased", label: "My Leads" };
  const history = useHistory();

  const filterOptions = [myAccount, teams];

  const onChangeFilter = value => {
    history.push(value.value);
  };

  useEffect(() => {
    if (filterType.value !== history.location.pathname) {
      const findListingTab = filterOptions.find(item => item.value === history.location.pathname);
      setFilterType(findListingTab);
    }
  }, [history.location.pathname]);

  return (
    <div className={cn(styles.filter, className)}>
      <div className={styles.filterMobile}>
        <span className={styles.label}>Filter by:</span>
        <SelectInput
          classNameWrap={styles.selectWrapper}
          className={styles.select}
          name="country"
          placeholder="Filter By"
          onChange={value => onChangeFilter(value)}
          options={filterOptions}
          value={filterType}
        />
      </div>

      <div className={styles.filterDesktop}>
        {filterOptions.map(item => {
          return (
            <NavLink to={item.value} key={item.value} activeClassName={styles.filterButtonActive} className={styles.filterButton}>
              {item.label}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default MainTabs;
