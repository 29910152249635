import React from "react";
import { useTranslation } from "react-i18next";

import { modals as modalsConstant, statuses } from "../../../../../constants/listings";
import { Modal } from "../../../../common/modal";
import ModalControl from "../../../../modalControl";
import styles from "./confirm.module.css";
import { Button } from "../../../../common/button";
import { priceFormatter, renderBrandIcon, getInitialsListing } from "../../../../../helpers";
import { paymentTypes } from "../../../../../constants/paymentTypes";
import { OverlayPreloader } from "../../../../common/preloader";
import modalStyles from "../../../../common/modal/modal.module.css";

const Confirm = ({
  openModal,
  closeModal,
  clearAllModals,
  activeUpgradeOptions,
  chargeForMembership,
  itemToEdit,
  selectedCard,
  defaultSource,
  paymentPreloaders,
  clearSavedPaymentData
}) => {
  const { t } = useTranslation();
  const { brand, last4, id } = selectedCard || { brand: "", last4: "", id: "" };
  const isDefault = defaultSource?.id === id;
  const { first_total: firstTotal, second_total: secondTotal } = activeUpgradeOptions;
  const isFree = firstTotal === 0 && secondTotal === 0;
  const isPendingPayment = statuses.pendingPayment === itemToEdit.status;
  const isExpired = statuses.expired === itemToEdit.publish_status;
  const isPremium = itemToEdit.is_premium;

  const handleCloseModal = () => {
    closeModal();
    clearSavedPaymentData();
  };

  const onExit = () => {
    closeModal();
    if (isFree) {
      openModal(modalsConstant.upgrade);
    } else {
      openModal(modalsConstant.payment);
    }
  };

  const onEditPaymentMethod = () => {
    closeModal();
    openModal(modalsConstant.payment);
  };

  const onEditPaymentPlan = () => {
    closeModal();
    openModal(modalsConstant.upgrade);
  };

  const onConfirm = () => {
    if (activeUpgradeOptions) {
      if (isPendingPayment) {
        chargeForMembership(
          {
            listing_id: itemToEdit.listing_id,
            card_token: id,
            added: itemToEdit.added,
            trackingData: { ...itemToEdit, price: priceFormatter.format(activeUpgradeOptions.first_total) }
          },
          paymentTypes.PENDING,
          true
        );
      } else if (isFree && !isExpired) {
        chargeForMembership(
          {
            listing_id: activeUpgradeOptions.listing_id,
            invoice_id: activeUpgradeOptions.invoice_id,
            invoice_item_id: activeUpgradeOptions.invoice_item_id,
            upgrade: activeUpgradeOptions.product_upgrade_id,
            coupon: activeUpgradeOptions?.coupon?.error ? false : activeUpgradeOptions?.coupon?.code,
            added: itemToEdit.added,
            trackingData: { ...itemToEdit, price: priceFormatter.format(activeUpgradeOptions.first_total) }
          },
          paymentTypes.UPGRADE,
          true
        );
      } else if (isExpired) {
        chargeForMembership(
          {
            listing_id: activeUpgradeOptions.listing_id,
            invoice_id: activeUpgradeOptions.invoice_id,
            invoice_item_id: activeUpgradeOptions.invoice_item_id,
            upgrade: activeUpgradeOptions.product_upgrade_id,
            plan_id: activeUpgradeOptions.plan_id,
            token: id,
            coupon: activeUpgradeOptions?.coupon?.error ? false : activeUpgradeOptions?.coupon?.code,
            added: itemToEdit.added,
            trackingData: { ...itemToEdit, price: priceFormatter.format(activeUpgradeOptions.first_total) }
          },
          isPremium ? paymentTypes.RENEW : paymentTypes.UPGRADE,
          true
        );
      } else {
        chargeForMembership(
          {
            listing_id: activeUpgradeOptions.listing_id,
            invoice_id: activeUpgradeOptions.invoice_id,
            invoice_item_id: activeUpgradeOptions.invoice_item_id,
            upgrade: activeUpgradeOptions.product_upgrade_id,
            token: id,
            coupon: activeUpgradeOptions?.coupon?.error ? false : activeUpgradeOptions?.coupon?.code,
            added: itemToEdit.added,
            trackingData: { ...itemToEdit, price: priceFormatter.format(activeUpgradeOptions.first_total) }
          },
          paymentTypes.UPGRADE,
          true
        );
      }
    }
  };

  const renderTitle = () => {
    if (isExpired) {
      return "RENEW SUBSCRIPTION";
    }

    if (isPendingPayment) {
      return t("pendingpayment");
    }

    return t("dashboard_listings_modal_upgrade_top_title");
  };

  const renderText = () => {
    if (isExpired) {
      return t("dashboard_listings_modal_renew_confirm_h2");
    }

    if (isPendingPayment) {
      return "Pending payment for the listing";
    }

    return t("dashboard_listings_modal_upgrade_confirm_h2");
  };

  return (
    <Modal closeModal={handleCloseModal} clearAllModals={clearAllModals} className={styles.confirm}>
      {paymentPreloaders.chargeForMembership && (
        <OverlayPreloader overlayClassName="sectionOverlayModal zIndex4" spinnerClassName={`zIndex4 ${modalStyles.spinnerOverlay}`} />
      )}
      {(paymentPreloaders.paymentMethods || paymentPreloaders.updateCard || paymentPreloaders.deleteCard) && (
        <OverlayPreloader overlayClassName="sectionOverlayModal zIndex4" spinnerClassName="zIndex4" />
      )}

      <h3 className={styles.subTitle}>{renderTitle()}</h3>
      <h1 className={styles.title}>{t("dashboard_listings_modal_upgrade_confirm_h1")}</h1>
      <h2 className={styles.description}>{renderText()}</h2>
      <div className={styles.listing}>
        <div className={styles.listingInitials}>{getInitialsListing(itemToEdit)}</div>
        <div>
          <p className={styles.listingType}>{itemToEdit.product_name}</p>
          {itemToEdit.attorney && <p className={styles.listingName}>{itemToEdit.attorney}</p>}
        </div>
      </div>
      <div className={styles.plan}>
        <div className={styles.planContent}>
          <p className={styles.planTitle}>{t("dashboard_listings_modal_upgrade_confirm_h3")}</p>
          <Button className={styles.buttonEdit} onClick={onEditPaymentPlan}>
            {t("dashboard_common_link_edit")}
          </Button>
        </div>
        <p className={styles.planName}>{`${activeUpgradeOptions.product} - ${activeUpgradeOptions.terms}`}</p>
      </div>
      <div className={styles.method}>
        <div className={styles.methodContent}>
          <p className={styles.methodTitle}>{t("dashboard_listings_modal_upgrade_confirm_h4")}</p>
          {!isFree && (
            <Button className={styles.buttonEdit} onClick={onEditPaymentMethod}>
              {t("dashboard_common_link_edit")}
            </Button>
          )}
        </div>
        <div className={styles.card}>
          {isFree ? (
            <span className={styles.cardNoPayment}>{t("dashboard_listings_modal_upgrade_confirm_zeropay")}</span>
          ) : (
            <>
              <div className={styles.cardImg}>
                <img src={renderBrandIcon(brand)} alt={`${brand} card`} />
              </div>
              <div>
                <span className={styles.cardNumber}>**** - **** - **** - {last4}</span>
                {isDefault && <p className={styles.cardDefault}>{t("dashboard_payment_methods_default_card")}</p>}
              </div>
            </>
          )}
        </div>
      </div>
      <ModalControl isExit onExit={onExit} onConfirm={onConfirm}>
        Pay {`${activeUpgradeOptions.first_total ? `(${priceFormatter.format(activeUpgradeOptions.first_total)})` : ""} `}
      </ModalControl>
    </Modal>
  );
};

export default Confirm;
