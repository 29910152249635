import React, { useEffect, useState, useRef } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";

import { apiKey } from "../../../constants/stripe";
import { AddCard } from "../../common/payment/addCard";
import EditCard from "../../common/payment/editCard";
import { OverlayPreloader } from "../../common/preloader";
import { Modal } from "../../common/modal";
import { Notification } from "../../common/notification";
import StripeInfo from "../stripeInfo";
import CardList from "../cardList";
// todo remove
// import EmptyState from "../emptyState";
import PaidSubscriptions from "../paidSubscriptions";
import UpdatePaymentMethod from "../updatePaymentMethod";
import styles from "./paymentMethods.module.css";
import { Button } from "../../common/button";
import Dropdown from "../dropdown";

const stripePromise = loadStripe(apiKey);

const defaultModals = { add: null, edit: null, updatedPaymentMethod: false };

const PaymentMethods = ({
  addCard,
  countries,
  clearTokenError,
  defaultSource,
  deleteCard,
  errorToken,
  paymentSuccess,
  paymentPreloaders,
  paymentMethods,
  paymentError,
  selectedCard,
  selectCard,
  setErrorToken,
  updateCard,
  getPaymentMethods,
  subscriptions,
  expiredSubscriptions,
  assignCardToSubscription,
  removeAddCardSuccess,
  children
}) => {
  const [paymentSubscription, setPaymentSubscription] = useState({});
  const [modals, setModal] = useState({});
  const [checkedRadio, setCheckedRadio] = useState(null);
  const [notes, setNotes] = useState({ addCard: null, deleteCard: null, updateCard: null, updatePaymentMethod: null });
  const inputCheck = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    setModal(defaultModals);
  }, []); // eslint-disable-line

  // Pick up success state change and timeout for notifications
  useEffect(() => {
    if (paymentSuccess.addCard) {
      setNotes({ addCard: true });
      setTimeout(() => setNotes({ addCard: false }), 7000);
    }
    if (paymentSuccess.updatePaymentMethod) {
      setNotes({ updatePaymentMethod: true });
      setTimeout(() => setNotes({ updatePaymentMethod: false }), 7000);
    }
    if (paymentSuccess.updateCard) {
      setNotes({ updateCard: true });
      setTimeout(() => setNotes({ updateCard: false }), 7000);
    }
    if (paymentSuccess.deleteCard) {
      setNotes({ deleteCard: true });
      setTimeout(() => setNotes({ deleteCard: false }), 7000);
    }
  }, [paymentSuccess]);

  const handleSelect = event => {
    selectCard(paymentMethods.find(({ id }) => id === event.target.value));
    setCheckedRadio(event.target.value);
  };

  useEffect(() => {
    if (paymentMethods && inputCheck.current) {
      inputCheck.current.click();
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    if (!checkedRadio && defaultSource?.id) {
      const findDefaultCard = paymentMethods.find(({ id }) => id === defaultSource?.id);

      if (findDefaultCard?.id) {
        selectCard(findDefaultCard);
        setCheckedRadio(findDefaultCard.id);
      }
    }
  }, [defaultSource?.id]); // eslint-disable-line

  const handleDelete = cardId => {
    const findCard = paymentMethods.find(({ id }) => id === cardId);

    deleteCard({ id: findCard.id, object: findCard.object, deleted: true });
  };

  useEffect(() => {
    if (paymentMethods === null) {
      getPaymentMethods();
    }
  }, []); // eslint-disable-line

  const handleActivePaymentModal = activePaymentSubscription => {
    setModal({ ...defaultModals, updatedPaymentMethod: true });
    setPaymentSubscription(activePaymentSubscription);
  };

  const handleCloseUpdatedPaymentMethod = () => {
    setModal({ ...defaultModals });
    setPaymentSubscription({});
  };

  useEffect(() => {
    if (notes.updatePaymentMethod) {
      setModal({ ...defaultModals });
    }
  }, [notes.updatePaymentMethod]); // eslint-disable-line

  return (
    <>
      {paymentError !== null && <p>{paymentError}</p>}
      <>
        {notes.deleteCard && <Notification success>{t("dashboard_payments_deletedmsg")}</Notification>}
        {notes.updateCard && <Notification success>{t("dashboard_common_saved")}</Notification>}
        {notes.addCard && <Notification success>{t("dashboard_payments_newcardadded")}</Notification>}
        {notes.updatePaymentMethod && <Notification success>{t("dashboard_payments_subscupdated")}</Notification>}

        <Elements stripe={stripePromise}>
          <>
            {modals.add && (
              <Modal closeModal={() => setModal({ add: false })} clearAllModals={() => setModal({ ...defaultModals })}>
                <AddCard
                  addCard={addCard}
                  clearTokenError={clearTokenError}
                  closeModal={() => setModal({ add: false })}
                  countries={countries}
                  errorToken={errorToken}
                  paymentPreloaders={paymentPreloaders}
                  paymentSuccess={paymentSuccess}
                  setErrorToken={setErrorToken}
                />
              </Modal>
            )}

            {selectedCard && modals.edit && (
              <Modal closeModal={() => setModal({ edit: false })} clearAllModals={() => setModal({ ...defaultModals })}>
                <EditCard
                  paymentPreloaders={paymentPreloaders}
                  paymentSuccess={paymentSuccess}
                  closeModal={() => setModal({ edit: false })}
                  currentCardDetails={selectedCard}
                  countries={countries}
                  updateCard={updateCard}
                />
              </Modal>
            )}
          </>
        </Elements>

        {modals.updatedPaymentMethod && (
          <UpdatePaymentMethod
            closeModal={handleCloseUpdatedPaymentMethod}
            clearAllModals={handleCloseUpdatedPaymentMethod}
            defaultSource={defaultSource}
            paymentMethods={paymentMethods}
            setModal={setModal}
            paymentSubscription={paymentSubscription}
            loading={paymentPreloaders.updatePaymentMethod}
            assignCardToSubscription={assignCardToSubscription}
          />
        )}

        <Dropdown title={t("dashboard_payments_subscriptions_title")}>
          <PaidSubscriptions
            expiredSubscriptions={expiredSubscriptions}
            subscriptions={subscriptions}
            handleActivePaymentModal={handleActivePaymentModal}
          />
        </Dropdown>

        <Dropdown title={t("dashboard_payments_history_title")}>{children}</Dropdown>

        <Dropdown title={t("dashboard_payments_methods_title")}>
          <section className={styles.paymentMethod}>
            <div className={styles.paymentContent}>
              {paymentPreloaders.deleteCard && <OverlayPreloader />}
              {paymentMethods?.length ? (
                <CardList
                  defaultSource={defaultSource}
                  paymentMethods={paymentMethods}
                  checkedRadio={checkedRadio}
                  handleSelect={handleSelect}
                  inputCheck={inputCheck}
                  setModal={setModal}
                  handleDelete={handleDelete}
                />
              ) : (
                <p>{t("dashboard_payments_methods_msg")}</p>
              )}

              <Button
                onClick={e => {
                  e.preventDefault();
                  setModal({ add: true });

                  if (paymentSuccess.addCard) {
                    removeAddCardSuccess();
                  }
                }}
                className={styles.addNewCardButton}
              >
                + {t("dashboard_payments_addcardbutton")}
              </Button>
            </div>

            <StripeInfo />
          </section>
        </Dropdown>
      </>

      {/*
      {(!paymentMethods || paymentMethods?.length === 0) && emptyState}
      */}
    </>
  );
};

export default PaymentMethods;
