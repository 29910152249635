import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ReactComponent as DropdownIcon } from "../../../../media/icons/dropdown-arrow.svg";
import { ReactComponent as LogoutIcon } from "../../../../media/icons/logout-main.svg";
import { enableScroll, disableScroll } from "../../../../helpers";
import { actions as teamsActions } from "../../../../actions/teams";
import styles from "./detailNavigation.module.css";

export const DetailNavigation = ({ onCloseHandler, keycloak }) => {
  const { t } = useTranslation();

  const { currentTeam } = useSelector(state => state.teams);
  const [isActiveDropdown, setIsActiveDropdown] = useState(false);

  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const setCurrentTeam = useCallback(team => dispatch(teamsActions.setCurrentTeam(team)), [dispatch]);
  const history = useHistory();

  const handleClick = () => {
    if (currentTeam.value) {
      setCurrentTeam({});
    }
  };

  const toggleDropdown = () => {
    setIsActiveDropdown(prev => !prev);
  };

  useEffect(() => {
    const handleUrlChange = () => {
      onCloseHandler();
    };

    const unlisten = history.listen(handleUrlChange);

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    disableScroll();
    return () => {
      enableScroll();
    };
  }, []);

  return (
    <>
      <nav ref={wrapperRef} className={styles.nav}>
        <Link className={styles.link} to="/" onClick={handleClick}>
          {t("dashboard_nav_feed")}
        </Link>

        <Link className={styles.link} to="/profile" onClick={handleClick}>
          {t("dashboard_nav_profile")}
        </Link>

        <Link className={styles.link} to="/inbox" onClick={handleClick}>
          {t("dashboard_nav_inbox")}
        </Link>

        <Link className={styles.link} to="/referrals" onClick={handleClick}>
          {t("dashboard_nav_referralrequests")}
        </Link>

        <Link className={styles.link} to="/question-answer" onClick={handleClick}>
          {t("dashboard_nav_questions")}
        </Link>

        <Link className={styles.link} to="/account" onClick={handleClick}>
          {t("dashboard_nav_account")}
        </Link>

        <span className={styles.indent} />

        <button type="button" className={cn(styles.dropdown, isActiveDropdown ? styles.dropdownActive : "")} onClick={toggleDropdown}>
          {t("dashboard_nav_businessservices")}
          <DropdownIcon className={cn(styles.icon, isActiveDropdown ? styles.iconActive : "")} />
        </button>

        {isActiveDropdown && (
          <>
            <Link className={styles.link} to="/my-organizations" onClick={handleClick}>
              {t("dashboard_nav_organizations")}
            </Link>
            <Link className={styles.link} to="/my-events" onClick={handleClick}>
              {t("dashboard_nav_events")}
            </Link>
            <Link className={styles.link} to="/my-jobs" onClick={handleClick}>
              {t("dashboard_nav_jobs")}
            </Link>
            <Link className={styles.link} to="/paid-leads?page=0&tab=notpurchased" onClick={handleClick}>
              {t("dashboard_nav_businessreferrals")}
            </Link>
          </>
        )}

        <span className={styles.indent} />

        <button
          type="button"
          className={cn(styles.logout, "button--clear")}
          onClick={() => keycloak.logout({ redirectUri: `${window.location.origin}/login` })}
        >
          <LogoutIcon />
          {t("dashboard_nav_logout")}
        </button>
      </nav>
      <div className={styles.background} onClick={onCloseHandler} role="button" tabIndex={-1} />
    </>
  );
};
