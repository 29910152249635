import React from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

import { customStylesMultiMobile as selectStyle } from "../../../../constants/select";
import styles from "./practicesMobile.module.css";

const areEqual = (prevProps, nextProps) => {
  return nextProps.activeFilters.practices.length === prevProps.activeFilters.practices.length;
};

export const PracticesMobile = React.memo(({ activeFilters, setActiveFilters }) => {
  const { answers, practices } = useSelector(state => state.questions);

  const questionPractices = [...new Set(answers?.flatMap(question => [question.practice1, question.practice2, question.practice3]))];
  const practicesForFilter = practices?.filter(practice => questionPractices.includes(practice.practice_id) && practice);
  const practicesForFilterMobile = practicesForFilter?.map(({ practice_id: value, title: label }) => ({ value, label }));

  return (
    <div className={styles.selectMember}>
      {Boolean(practicesForFilterMobile.length) && (
        <Select
          isMulti
          options={practicesForFilterMobile}
          onChange={opts => setActiveFilters({ ...activeFilters, practices: opts?.map(({ value }) => value) || [], isFirstRender: false })}
          placeholder="Filter by Practice Area..."
          styles={selectStyle}
        />
      )}
    </div>
  );
}, areEqual);
