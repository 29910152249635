import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import { URL as URLRegex, textareaRegex } from "../../../constants/regex";
import Dropdown from "../dropdown";
import Label from "../label";
import Input from "../../common/input";
import Select from "../select";
import { getCountryNameFromCode, getStateNameFromCode, renderSelectCountries, renderSelectStates } from "../../../helpers/select";
import { customStyles as selectStyles } from "../../../constants/select";
import styles from "./aboutCompany.module.css";
import { useValidationError } from "../../../helpers/hooks";

const AboutCompany = ({ submitChanges, countries, states, parentCompany, validation, showRequiredText }) => {
  const refButton = useRef();
  if (Object.keys(validation).length && !validation.enabled) {
    return null;
  }
  const isRequired = Object.keys(validation).length ? validation.required : true;
  const isRequiredText = validation.required && !parentCompany.name && showRequiredText;
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const containerRef = useRef();

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);

      if (isEdited) {
        handleSubmit();
        setIsEdited(false);
      }
    }
  };

  const { t } = useTranslation();
  const title = validation?.custom_name;

  const validate = values => {
    const errors = {};
    const { description, name, website: websiteField, zipcode, country, address, state, city } = values;
    const requiredText = "This field is mandatory";
    const invalidUrlText = "Please use the full URL";

    if (!description) {
      errors.description = requiredText;
    }

    if (!name) {
      errors.name = requiredText;
    }

    if (description && description.match(textareaRegex)) {
      errors.bioHasLinks = requiredText;
    }

    if (!websiteField) {
      errors.website = requiredText;
    }

    if (websiteField && !websiteField.match(URLRegex)) {
      errors.website = invalidUrlText;
    }

    if (!country) {
      errors.country = requiredText;
    }

    if (!zipcode) {
      errors.zipcode = requiredText;
    }

    if (!address) {
      errors.address = requiredText;
    }

    if (!city) {
      errors.city = requiredText;
    }

    if (!state) {
      errors.state = requiredText;
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: parentCompany.name || "",
      description: parentCompany.description || "",
      website: parentCompany.website || "",
      address: parentCompany.address || "",
      city: parentCompany.city || "",
      state: { title: parentCompany.state || "" },
      country: { title: parentCompany.country || "" },
      zipcode: parentCompany.zipcode || "",
      isEdited: false,
      prkey: parentCompany.prkey
    },
    onSubmit: values => {
      submitChanges(
        { parent_company: [{ ...values, country: values.country.title, state: values.state.title }] },
        "parent_company",
        "save_parent_company"
      );
    },
    validate
  });

  const { errors, touched, values, handleSubmit, setFieldTouched, setFieldValue } = formik;

  const handleBlur = fieldName => {
    setFieldTouched(fieldName, true);
  };

  const handleChange = e => {
    setFieldValue(e.target.name, e.target.value);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleSelectChange = (newValue, actionMeta) => {
    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }

    setFieldValue(actionMeta.name, newValue);
  };

  const onHandleSubmit = () => {
    if (
      (values.name ||
        values.description ||
        values.website ||
        values.street ||
        values.country ||
        values.zip ||
        values.state ||
        values.city) &&
      values.isEdited
    ) {
      handleSubmit();
    }
  };

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (isTouched) {
          setIsTouched(false);

          if (isEdited) {
            handleSubmit();
            setIsEdited(false);
          }
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [isEdited]);

  const countryOptions = renderSelectCountries(countries, false).map((item, index) => ({ id: index, title: item }));
  const stateOptions = renderSelectStates(values.country.title, states, false).map((item, index) => ({ id: index, title: item }));

  useValidationError(Object.values(errors).some(item => Boolean(item)) ? { aboutCompany: "Error" } : "isRemove", "aboutCompany");

  return (
    <div ref={containerRef}>
      <Dropdown
        isRequired={isRequired}
        isRequiredText={isRequiredText}
        title={title}
        handleMouseMove={handleMouseMove}
        handleMouseLeave={handleMouseLeave}
        handleTouchStart={handleTouchStart}
      >
        <div className={styles.content}>
          <Label className={styles.label} htmlFor="name">
            Organization Name
          </Label>
          <Input
            type="text"
            placeholder="Law Firm"
            className={styles.inputWebsite}
            error={errors.name}
            id="name"
            isInvalid={errors.name && touched.name}
            name="name"
            onBlur={() => handleBlur("name")}
            onChange={handleChange}
            value={values.name}
            classNameError={styles.errorText}
          />
        </div>

        <div className={styles.content}>
          <Label className={styles.label} htmlFor="description">
            Organization Name
          </Label>
          <textarea
            name="description"
            id="description"
            placeholder="Enter the job description here"
            className={cn(styles.textarea, errors.description ? styles.textareaError : "")}
            value={values.description}
            onChange={handleChange}
          />

          {errors.description && <span className={styles.errorText}>Required</span>}
        </div>

        <span className={cn(styles.info, errors.bioHasLinks ? styles.infoActive : "")}>
          {t("dashboard_single_listing_popup_biography_html")}
        </span>

        <div className={styles.content}>
          <Label className={styles.label} htmlFor="website">
            Company Website
          </Label>
          <Input
            type="text"
            placeholder="ex. https://www.andybernanrd.com"
            className={styles.inputWebsite}
            error={errors.website}
            id="website"
            isInvalid={errors.website && touched.website}
            name="website"
            onBlur={() => handleBlur("website")}
            onChange={handleChange}
            value={values.website}
            classNameError={styles.errorText}
            classNameInvalid={styles.inputInvalid}
          />
        </div>

        <div className={styles.companyContent}>
          <Label className={styles.label} htmlFor="address">
            Company Headquarters
          </Label>
          <Input
            classNameWrap={styles.inputWrap}
            className={styles.input}
            error={errors.address}
            id="address"
            isInvalid={errors.address && touched.address}
            name="address"
            onBlur={() => handleBlur("address")}
            onChange={handleChange}
            value={values.address}
            placeholder={t("inputstreet")}
          />

          <div className={styles.flex}>
            <div>
              <Select
                error={errors.country}
                isInvalid={errors.country && touched.country}
                getOptionLabel={option => getCountryNameFromCode(countries, option.title)}
                getOptionValue={option => option.title}
                inputId="country"
                name="country"
                options={countryOptions}
                onBlur={() => handleBlur("country")}
                handleChange={handleSelectChange}
                styles={selectStyles}
                required
                value={[values.country]}
                classNameWrap={styles.select}
                placeholder={t("inputcountry")}
              />
            </div>

            <div>
              <Select
                error={errors.state}
                getOptionLabel={option => getStateNameFromCode(states, option.title)}
                getOptionValue={option => option.title}
                id="state"
                inputId="state"
                isInvalid={errors.state && touched?.state}
                name="state"
                noOptionsMessage={() => "Please select your country first"}
                onBlur={() => handleBlur("state")}
                handleChange={handleSelectChange}
                options={states && stateOptions}
                value={[values.state]}
                classNameWrap={styles.select}
                placeholder={t("inputstate")}
              />
            </div>
          </div>

          <div className={styles.flex}>
            <div>
              <Input
                error={errors.city}
                id="city"
                isInvalid={errors.city && touched.city}
                name="city"
                onBlur={() => handleBlur("city")}
                onChange={handleChange}
                value={values.city}
                className={styles.input}
                classNameWrap={styles.inputWrap}
                placeholder={t("inputcity")}
              />
            </div>
            <div>
              <Input
                error={errors.zipcode}
                id="zipcode"
                isInvalid={errors.zipcode && touched.zipcode}
                name="zipcode"
                onBlur={() => handleBlur("zipcode")}
                onChange={handleChange}
                value={values.zipcode}
                className={styles.input}
                classNameWrap={styles.smallWrapInput}
                placeholder={t("inputtzip")}
              />
            </div>
          </div>
          <button ref={refButton} type="button" className={styles.hidden} onClick={onHandleSubmit} />
        </div>
      </Dropdown>
    </div>
  );
};

export default AboutCompany;
